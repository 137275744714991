import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
import i18n from './../i18n';

import './ModalImage.css';
import './../App.css';


const ModalImage = props => {
  const { t } = useTranslation();
  const {showModalImage, handleCloseModalImage, modalImage, changeLanguage} = props

  useEffect(() => {

  }, [])

  return (
    <Modal show={showModalImage} onHide={handleCloseModalImage} className={`modal-image`}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body >
        <a href={t('form')} target="_blank" rel="noopener noreferrer" title="Bnoble" >
          <img src={modalImage} alt="BNoble" />
        </a>
        <div className="modal-languages">
          <div className="language-options-modalx">
            <div className={`language_modalx ${i18n.language === 'en' ? 'selected' : ''}`} onClick={() => { changeLanguage('en') }}>
              <span>
                EN
              </span>
            </div>
            <div className={`language_modalx ${i18n.language === 'es' ? 'selected' : ''}`} onClick={() => { changeLanguage('es') }}>
              <span>
                ES
              </span>
            </div>
            <div className={`language_modalx ${i18n.language === 'po' ? 'selected' : ''}`} onClick={() => { changeLanguage('po') }}>
              <span>
                PT
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalImage;
