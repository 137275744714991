import React from 'react';

import './PrivacyPolicy.css';


function PrivacyPolicy() {

  return (
    <div className="PrivacyPolicy" id="PrivacyPolicy">
      <div className="wrapper">
        <br/>
        <br/>
        <h1><span>T&eacute;rminos y Condiciones y Pol&iacute;tica de Privacidad</span></h1>
        <br/>
        <p><span><strong>Greenbook Academy</strong> y sus compa&ntilde;&iacute;as afiliadas. estamos comprometidos con el manejo responsable de su informaci&oacute;n.&nbsp; Dando cumplimiento a las leyes y/o regulaciones vigentes, lo invitamos a leer nuestra Pol&iacute;tica de Tratamiento de datos. La informaci&oacute;n recopilada y tratada, ser&aacute; utilizada con fines comerciales, publicitarios y promocionales directamente relacionados con Greenbook Academy&nbsp; y sus afiliadas.</span></p>
        <h2><span>Normatividad</span></h2>
        <p><span>Con el objetivo de dar cumplimiento a la legislaci&oacute;n vigente en materia de protecci&oacute;n de datos, y dem&aacute;s normas que la adicionen, complementen, desarrollen, deroguen o modifiquen), se presenta la pol&iacute;tica de tratamiento de datos para los titulares de la informaci&oacute;n de los datos personales que se encuentren registrados en las bases de datos de Greenbook Academy y sus afiliadas.</span></p>
        <p><span>Esta Pol&iacute;tica de Tratamiento de Datos Personales tiene como objeto informarle sobre los aspectos relevantes en relaci&oacute;n con la recolecci&oacute;n, finalidad, uso, manejo y transferencia de datos personales que Greenbook Academy y sus afiliadas, realizan, tambi&eacute;n se dan a conocer sus derechos como titular.</span></p>
        <h2><span>Greenbook Academy y sus afiliadas.</span></h2>
        <p><span>Greenbook Academy y sus afiliadas. act&uacute;an como responsables del tratamiento de los datos registrados en sus bases de datos y de terceros encargados del Tratamiento de datos de las empresas.</span></p>
        <h2><span>Recopilaci&oacute;n de datos</span></h2>
        <p><span>Greenbook Academy y sus afiliadas. podr&aacute;n solicitar informaci&oacute;n del titular v&iacute;a correo electr&oacute;nico, f&iacute;sica, telef&oacute;nica, sitio web y/osistemas de terceros a clientes, proveedores, colaboradores, empleados, prospectos, aspirantes a vacantes de la empresa, usuarios del portal web o usuarios participantes de actividades realizadas por la (s) empresa (s), casos en los cuales solicitar&aacute;n la autorizaci&oacute;n para el tratamiento de datos.</span></p>
        <p><span>Greenbook Academy y sus afiliadas. En ning&uacute;n momento solicitar&aacute;n informaci&oacute;n catalogada como sensible tal como la relacionada con la orientaci&oacute;n pol&iacute;tica, religiosa, sexual o filos&oacute;fica, el origen racial o &eacute;tnico, la pertenencia o participaci&oacute;n a sindicatos u organizaciones sociales o de derechos humanos.</span></p>
        <p><span>Greenbook Academy y sus afiliadas. podr&aacute;n solicitar informaci&oacute;n a los usuarios por medio de portal Web, correo electr&oacute;nico, f&iacute;sica, telef&oacute;nica y sistemas de terceros, cuya respuesta en cualquier caso ser&aacute; voluntaria. Al ingresar a estos sistemas descritos, el usuario acepta que en caso de dar informaci&oacute;n personal lo hace de manera voluntaria y adquiere el compromiso de suministrar informaci&oacute;n personal correcta y verdadera, as&iacute; como proceder a la actualizaci&oacute;n o eliminaci&oacute;n de sus datos cada vez que se requiera.</span></p>
        <h2><span>Autorizaci&oacute;n</span></h2>
        <p><span>Greenbook Academy y sus afiliadas. podr&aacute;n usar cualquier mecanismo para obtener la autorizaci&oacute;n de tratamiento de datos ya sea escrito, web, de forma oral, v&iacute;a correo electr&oacute;nico o mediante conducta inequ&iacute;voca del titular que permitan concluir de forma razonable que se otorg&oacute; la autorizaci&oacute;n.</span></p>
        <p>&nbsp;</p>
        <p><span>La autorizaci&oacute;n del titular </span><strong>no</strong><span> ser&aacute; necesaria cuando se trate de:</span></p>
        <ul>
        <li aria-level="1"><span>Informaci&oacute;n requerida por Greenbook Academy y sus afiliadas en ejercicio de sus funciones legales o por orden judicial.</span></li>
        <li aria-level="1"><span>Datos de naturaleza p&uacute;blica.</span></li>
        <li aria-level="1"><span>Casos de urgencia m&eacute;dica o sanitaria.</span></li>
        <li aria-level="1"><span>Tratamiento de informaci&oacute;n autorizado por la ley para fines hist&oacute;ricos, estad&iacute;sticos o cient&iacute;ficos.</span></li>
        <li aria-level="1"><span>Datos relacionados con el Registro Civil de las Personas.</span></li>
        </ul>
        <h2><span>Uso de tratamiento de datos</span></h2>
        <p><span>En desarrollo de su objeto social, del mantenimiento de v&iacute;nculos comerciales, del cumplimiento de contratos de trabajadores, colaboradores, clientes, socios y del cumplimiento de &oacute;rdenes de autoridades judiciales y/o administrativas, Greenbook Academy y sus afiliadas. podr&aacute; dar tratamiento a los datos personales para:</span></p>
        <ul>
        <li aria-level="1"><span>Lograr un mayor entendimiento de los gustos, preferencias, necesidades de clientes, colaboradores o prospectos de clientes.</span></li>
        <li aria-level="1"><span>Dar respuesta a alg&uacute;n requerimiento, solicitud, queja o inquietud enviada por un titular.</span></li>
        <li aria-level="1"><span>Informar sobre productos, servicios, eventos o promociones realizados por Greenbook Academy y sus afiliadas.</span></li>
        <li aria-level="1"><span>Desarrollar actividades de mercadeo o promocionales.</span></li>
        <li aria-level="1"><span>Dar cumplimiento a obligaciones contractuales, financieras o comerciales contra&iacute;das con el titular.</span></li>
        <li aria-level="1"><span>Evaluar la calidad de productos o servicios prestados por la empresa.</span></li>
        <li aria-level="1"><span>Realizar estudios internos sobre el cumplimiento de las relaciones comerciales, laborales y estudios de mercado.</span></li>
        <li aria-level="1"><span>Responder requerimientos legales de entidades administrativas y judiciales.</span></li>
        </ul>
        <p><span>&nbsp;Teniendo en cuenta los tratamientos descritos anteriormente Greenbook Academy y sus afiliadas. podr&aacute;:</span></p>
        <ul>
        <li aria-level="1"><span>Conocer, almacenar y procesar toda la informaci&oacute;n suministrada por el titular</span></li>
        <li aria-level="1"><span>Verificar, comprobar, validar, investigar o comparar la informaci&oacute;n suministrada por el titular, con cualquier informaci&oacute;n que se disponga leg&iacute;timamente.</span></li>
        <li aria-level="1"><span>Estudiar, analizar, personalizar y utilizar la informaci&oacute;n suministrada por el titular para el seguimiento, desarrollo y/o mejoramiento, tanto individual como general, de condiciones de servicio, administraci&oacute;n, seguridad y atenci&oacute;n, as&iacute; como para la implementaci&oacute;n de planes de mercadeo, campa&ntilde;as, beneficios especiales y promociones.</span></li>
        </ul>
        <h2><span>Deberes como responsables del tratamiento de datos</span></h2>
        <p><span>Los Responsables del Tratamiento deber&aacute;n cumplir los siguientes deberes, sin Perjuicio de las dem&aacute;s disposiciones previstas en la ley vigente y en otras regulaciones que rijan su actividad:</span></p>
        <ul>
        <li aria-level="1"><span>Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de h&aacute;beas data.</span></li>
        <li aria-level="1"><span>Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva autorizaci&oacute;n otorgada por el titular.</span></li>
        <li aria-level="1"><span>Informar debidamente al titular sobre la finalidad de la recopilaci&oacute;n, el uso dado a sus datos personales y los derechos que le asisten por virtud de la autorizaci&oacute;n otorgada.</span></li>
        <li aria-level="1"><span>Tramitar las consultas y reclamos formulados en los t&eacute;rminos se&ntilde;alados en la presente pol&iacute;tica.</span></li>
        <li aria-level="1"><span>Conservar la informaci&oacute;n bajo las condiciones de seguridad necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento.</span></li>
        <li aria-level="1"><span>Actualizar o Eliminar la informaci&oacute;n cuando sea necesario.</span></li>
        <li aria-level="1"><span>Suministrar al Encargado del Tratamiento, seg&uacute;n el caso, &uacute;nicamente datos cuyo Tratamiento est&eacute; previamente autorizado de conformidad con lo previsto en la presente pol&iacute;tica.</span></li>
        <li aria-level="1"><span>Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la informaci&oacute;n del titular.</span></li>
        <li aria-level="1"><span>Informar a la autoridad de protecci&oacute;n de datos cuando se presenten violaciones a los c&oacute;digos de seguridad y existan riesgos en la administraci&oacute;n de la informaci&oacute;n de los titulares.</span></li>
        <li aria-level="1"><span>Cumplir las instrucciones y requerimientos que imparta los entes reguladores.</span></li>
        </ul>
        <h2><span>Deberes como encargados de tratamiento de datos</span></h2>
        <p><span>Como Encargado del tratamiento de datos o en caso de realizar el tratamiento de datos en nombre de otra entidad u organizaci&oacute;n (Responsable del tratamiento) Greenbook Academy y sus afiliadas. deber&aacute; cumplir los siguientes deberes:</span></p>
        <ul>
        <li aria-level="1"><span>Establecer que el responsable del tratamiento est&eacute; autorizado para suministrar los datos personales que tratar&aacute; como encargado.</span></li>
        <li aria-level="1"><span>Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de h&aacute;beas data.</span></li>
        <li aria-level="1"><span>Conservar la informaci&oacute;n bajo las condiciones de seguridad necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento.</span></li>
        <li aria-level="1"><span>Realizar oportunamente la actualizaci&oacute;n, rectificaci&oacute;n o eliminaci&oacute;n de los datos.</span></li>
        <li aria-level="1"><span>Actualizar la informaci&oacute;n reportada por los Responsables del tratamiento.</span></li>
        <li aria-level="1"><span>Tramitar las consultas y los reclamos formulados por los titulares en los t&eacute;rminos se&ntilde;alados en la presente pol&iacute;tica.</span></li>
        <li aria-level="1"><span>Registrar y catalogar los procesos de reclamos realizados por parte del titular o autoridad competente.</span></li>
        <li aria-level="1"><span>Abstenerse de circular informaci&oacute;n que est&eacute; siendo controvertida por el titular y cuyo bloqueo haya sido ordenado por entes regulatorios.</span></li>
        <li aria-level="1"><span>Informar a los entes regulatorios cuando se presenten violaciones a los c&oacute;digos de seguridad y existan riesgos en la administraci&oacute;n de la informaci&oacute;n de los titulares.</span></li>
        </ul>
        <h2><span>Derechos del titular de la informaci&oacute;n</span></h2>
        <p><span>El titular de la informaci&oacute;n que reposa en las bases de datos de Greenbook Academy y sus afiliadas. tiene los siguientes derechos:</span></p>
        <ul>
        <li aria-level="1"><span>Conocer, actualizar, verificar y rectificar sus datos personales frente a los responsables o encargados del tratamiento.</span></li>
        <li aria-level="1"><span>Acceder en forma gratuita a sus datos personales que hayan sido objeto de tratamiento.</span></li>
        <li aria-level="1"><span>Tener acceso en cualquier momento a la pol&iacute;tica de tratamiento de datos que se le aplica a sus datos personales.</span></li>
        <li aria-level="1"><span>Tener conocimiento del medio de contacto por el cual puede acceder a sus derechos como titular ante el responsable o encargado del tratamiento.</span></li>
        <li aria-level="1"><span>Revocar total o parcialmente la autorizaci&oacute;n del tratamiento de sus datos por parte de Greenbook Academy y sus afiliadas. En caso de revocatoria total por parte del titular Greenbook Academy y sus afiliadas. solo podr&aacute; usar los datos en ejercicio de sus funciones legales o por orden judicial.</span></li>
        </ul>
        <h2><span>Procedimientos para consultas, solicitudes o quejas por parte del titular</span></h2>
        <p><span>El titular podr&aacute; ejercer sus derechos ante Greenbook Academy y sus afiliadas. realizando previa solicitud por medio de los canales ya mencionados, toda solicitud o reclamo debe contener un documento que validen la identidad del titular.</span></p>
        <p><span>Toda solicitud ser&aacute; atendida en un plazo m&aacute;ximo de sesenta</span><span>&nbsp;(60)</span><span> d&iacute;as h&aacute;biles contados a partir de la fecha del recibo de la solicitud. En la eventualidad de no ser posible atender la solicitud en dicho t&eacute;rmino, se informar&aacute; dentro del mismo t&eacute;rmino, expresando los motivos que dan lugar a la imposibilidad, al igual que la fecha en que se dar&aacute; respuesta, la cual no podr&aacute; ser superior a </span><span>veinte (20)</span><span> d&iacute;as h&aacute;biles siguientes al vencimiento del primer plazo.</span></p>
        <h2><span>Actualizaci&oacute;n y vigencia</span></h2>
        <p><span>Greenbook Academy y sus afiliadas. se reserva el derecho de actualizar esta pol&iacute;tica en cualquier momento, raz&oacute;n por la cual se invita a todo titular a consultar regular o peri&oacute;dicamente la p&aacute;gina web a trav&eacute;s de la cual se mantendr&aacute; la &uacute;ltima versi&oacute;n de esta Pol&iacute;tica.</span></p>
        <h2><span>Actualizaci&oacute;n o retiro de la base de datos</span></h2>
        <p><span>Como titular podr&aacute; ejercer en cualquier momento su derecho de conocer, actualizar, rectificar y solicitar la eliminaci&oacute;n de sus datos personales y/o revocar su autorizaci&oacute;n, conect&aacute;ndose al correo electr&oacute;nico info@greenbookacademy.com, o por Comunicaci&oacute;n escrita a la direcci&oacute;n 1815 barker drive, Winter Park, Florida.</span></p>
        <p><br /><br /></p>
      </div>
    </div >
  );
}

export default PrivacyPolicy;
