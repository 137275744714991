import React from 'react';
import { Link } from "react-router-dom";

export default function NotFound() {
	return (
		<div className="PrivacyPolicy" id="PrivacyPolicy">
      <div className="wrapper">
        <br/>
        <br/>
        <br/>
        <br/>
    		<h2>404</h2>
        <h1>Page Not Found</h1>
        <p>The specified file was not found on this website. Please check the URL for mistakes and try again.</p>
    		<p>Here are some helpful links:</p>
    		<Link to='/'>Home</Link>
        <br/>
        <Link to='/workshop'>Workshop</Link>
        <br/>
        <br/>
		</div>
    </div>
	)
}