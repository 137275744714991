import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import partner1 from '../Assets/img/logo1.png';
import partner2 from '../Assets/img/logo2.png';
import partner3 from '../Assets/img/logo3.png';
import partner4 from '../Assets/img/logo4.png';
import partner5 from '../Assets/img/florida_department_agriculture.png';
import partner6 from '../Assets/img/bnoble-logo.png';

import './partners.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
    },
    bigtablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 600, min: 340 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 340, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const Partners = () => {
    const { t } = useTranslation();
    return (
        <div className="partners__section" id="partnersSection">
            <div className="partners__container">
                <h2>{t("partners-1")}</h2>
                <h3>{t("partners-2")}</h3>
                <div className="partners__logos">
                    <img className="partners-bnoble" src={partner6} alt="B NOBLE" />
                    <Carousel responsive={responsive} infinite={true} >
                        <div className="partners-image"><img src={partner1} alt="Latinas Cannapreneurs" id="individual__partner-1" /></div>
                        <div className="partners-image"><img src={partner2} alt="Marijuana Policy Project" id="individual__partner-2" /></div>
                        <div className="partners-image"><img src={partner3} alt="MCBA" id="individual__partner-3" /></div>
                        <div className="partners-image"><img src={partner4} alt="The Cannabis Alliance" id="individual__partner-4" /></div>
                        <div className="partners-image"><img src={partner5} alt="Florida Department of Agriculture and Customer Services" id="individual__partner-5" /></div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default Partners;