import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import i18n from './../i18n';
import emailjs from 'emailjs-com';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { gtag_report_conversion } from '../utils/gtag_report_conversion'

import './Contact.css';

const Contact = props => {
  const {focusCampaign, source} = props
  const [user, setUser] = useState({ user_name: "", user_email: "", user_phone: "" });
  const [messageSent, setMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let userEmailSession = sessionStorage.getItem('userEmail')
    let userNameSession = sessionStorage.getItem('userName')
    let userPhoneSession = sessionStorage.getItem('userPhone')

    let name = ''
    let email = ''
    let phone = ''

    if (userNameSession) {
      name = userNameSession
    }

    if (userEmailSession) {
      email = userEmailSession
    }

    if (userPhoneSession) {
      phone = userPhoneSession
    }

    setUser({ 'user_name': name, 'user_email': email, 'user_phone': phone });

  }, [])

  const handlePhoneChange = (value) => {
    setUser({ ...user, 'user_phone': value });
  };

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(e.target);
    emailjs.sendForm('gmail', 'template_MQB5QStV', e.target, 'user_81bamn3UXujaBnDZYYrni')
      .then((result) => {
          setMessageSent(true)
      }, (error) => {
          console.log(error.text);
          window.alert(t('msg_error2'))
      })
      .then(() => {
          setMessageSent(true);
          setIsLoading(false);
          gtag_report_conversion()
      });
  }

    return (
        <div className="contact__section" id="contactSection">
            <div className="contact__container">
                {!messageSent &&
                    <React.Fragment>
                        <div className="contact-header">
                            <h2>{t('Slider1-1')}<span>{t('Slider1-2')}</span></h2>
                            <h3>{t('Slider1-3')}</h3>
                        </div>
                        <form onSubmit={submitHandler} className="contact-main">
                            <input type="hidden" name="email_number" value={Math.random() * 100000 | 0} />
                            <input type="hidden" name="focus_campaign" value='contact' />
                            <input type="hidden" name="source" value={source} />
                            <input type="hidden" name="language" value={i18n.language === 'en' ? 'English' : i18n.language === 'es' ? 'Español' : 'Portugues'} />
                                <div className="contact-main-column">
                                    <div className="form-group responsive-input">
                                        <input type="text" name="user_name" className="form-control inline" placeholder={`${t('contact-3')}`} value={user.user_name} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group responsive-input">
                                        <input type="email" className="form-control inline" id="emailAddress" name="user_email" placeholder={`${t('contact-4')}`} value={user.user_email} onChange={handleChange} required />
                                    </div>
                                    <div className="modalx_renglones1">
                                        <select className="modalx_innerrenglon1 " name="user_career" placeholder={<div className="select-placeholder-text">Select category</div>} >
                                            {focusCampaign === 'jobfair' ?
                                              <option value="" disabled selected hidden>{`${t('contact-jobfair-6')}`}</option>
                                            :
                                              <option value="" disabled selected hidden>{`${t('contact-6')}`}</option>
                                            }
                                            <option value={`${t('career-option-1')}`}>{`${t('career-option-1')}`}</option>
                                            <option value={`${t('career-option-2')}`}>{`${t('career-option-2')}`}</option>
                                            <option value={`${t('career-option-3')}`}>{`${t('career-option-3')}`}</option>
                                            <option value={`${t('career-option-4')}`}>{`${t('career-option-4')}`}</option>
                                            <option value={`${t('career-option-5')}`}>{`${t('career-option-5')}`}</option>
                                            <option value={`${t('career-option-6')}`}>{`${t('career-option-6')}`}</option>
                                            <option value={`${t('career-option-7')}`}>{`${t('career-option-7')}`}</option>
                                            <option value={`${t('career-option-8')}`}>{`${t('career-option-8')}`}</option>
                                            <option value={`${t('career-option-9')}`}>{`${t('career-option-9')}`}</option>
                                            <option value={`${t('career-option-10')}`}>{`${t('career-option-10')}`}</option>
                                            <option value={`${t('career-option-11')}`}>{`${t('career-option-11')}`}</option>
                                            <option value={`${t('career-option-12')}`}>{`${t('career-option-12')}`}</option>
                                        </select>
                                    </div>
                                    <div className="contact-opcion">
                                        <input className="form-check-input" type="radio" name="inquiryType" id="Faculty" value="Faculty" />
                                        <label htmlFor="Faculty">{t('modal_8')}</label>
                                    </div>
                                </div>
                                <div className="contact-main-column">
                                    <div className="form-group responsive-input">
                                        <PhoneInput
                                            international
                                            name="user_phone"
                                            placeholder={`${t('contact-5')}`}
                                            value={user.user_phone}
                                            onChange={handlePhoneChange}
                                            style={{
                                                background: "white",
                                                borderRadius: "5px",
                                                padding: "5px 10px"
                                            }}
                                            required
                                        />
                                    </div>
                                    <div className="textarea-inline">
                                        <div className="form-group">
                                            <textarea className="form-control textarea" id="emailInput" name="message" placeholder={`${t('contact-7')}`} />
                                        </div>
                                        {isLoading &&
                                            <div className="contact-loading-spinner__container">
                                                <ReactLoading type="spinningBubbles" color="#268869" height={60} width={60} />
                                            </div>
                                        }
                                        {!isLoading &&
                                            <button type="submit" className="btn send-btn">{`${t('contact-8')}`}</button>
                                        }
                                    </div>
                                </div>
                        </form>
                    </React.Fragment>
                }
                {(messageSent && !isLoading) &&
                    <div className="success-message-sent">
                        <h2>{t('sent-1')}</h2>
                        <h5>{t('sent-2')}</h5>
                        <p>{t('sent-3')}</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default Contact;