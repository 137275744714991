import React, { useEffect, useState, useLayoutEffect } from 'react';

import MainNavigation from './components/MainNavigation';
import Certificates from './components/Certificates';
import Contact from './components/Contact';
import Cover from './components/Cover';
import Footer from './components/Footer';
//import Opinions from './components/Opinions';
import Partners from './components/partners';
import About from './components/About';
import Demand from './components/Demand';
import Instructores from './components/Instructores';
import Workshop from './components/Workshop';
// import Media from './components/Media';
import Calendar from './components/Calendar';
import PrivacyPolicy from './PrivacyPolicy';
import NotFound from './NotFound';

import emailjs from 'emailjs-com';
import i18n from './i18n';
//import { Link } from 'react-scroll';
import { useParams } from 'react-router-dom'
import { gtag_report_conversion } from './utils/gtag_report_conversion'

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ModalVideo from 'react-modal-video'

import { useTranslation } from 'react-i18next';
import './App.css';
import CustomModal from './components/CustomModal';
import ModalImage from './components/ModalImage';
//import Modal from './components/Modal';

import popupBnobleEn from './Assets/img/FL_BNobleM_en.png';
import popupBnobleEs from './Assets/img/FL_BNobleM_es.png';
import popupBnoblePt from './Assets/img/FL_BNobleM_pt.png';
import popupBnobleEn15 from './Assets/img/FL_BNobleM_en15.png';
import popupBnobleEs15 from './Assets/img/FL_BNobleM_es15.png';
import popupBnoblePt15 from './Assets/img/FL_BNobleM_pt15.png';

const images = {
  '0': {
    'en': popupBnobleEn,
    'es': popupBnobleEs,
    'po': popupBnoblePt,
  },
  '15': {
    'en': popupBnobleEn15,
    'es': popupBnobleEs15,
    'po': popupBnoblePt15,
  }
}

function App() {
  const { t } = useTranslation();
  const app = useParams().app || ''

  const [messageSent, setMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(false);
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const [video, setVideo] = useState('0');
  const [videoModal, setVideoModal] = useState('0');
  const [focusCampaign, setFocusCampaign] = useState('grant');
  const [grantText, setGrantText] = useState('text1');
  const [source, setSource] = useState('');
  const [page, setPage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [timePage, setTimePage] = useState(0);
  const [show, doShow] = useState({
    bottomBar: false
  });
  console.log(show)

  //const [bottomBarDisplayed, setBottomBarDisplayed] = useState(true);
  //const ourRef = useRef(null);

  useEffect(() => {
    updateModalImage()
  }, [timePage]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateModalImage = () => {
    setModalImage(images[timePage][i18n.language])
  }

  useLayoutEffect(() => {
    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (scrollPos > 1200) {
        doShow(state => ({ ...state, bottomBar: false }));
      } else if (scrollPos <= 1200) {
        doShow(state => ({ ...state, bottomBar: false }))
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    if (focusCampaign === 'jobfair2' && data.getAll('workshop').length === 0) {
      window.alert(t('msg_error'))
    } else {
      setIsLoading(true);
      emailjs.sendForm('gmail', 'template_MQB5QStV', e.target, 'user_81bamn3UXujaBnDZYYrni')
        .then((result) => {
          setMessageSent(true)
        }, (error) => {
          setIsLoading(false);
          console.log(error.text);
          window.alert(t('msg_error2'))
        })
        .then(() => {
          setMessageSent(true);
          setIsLoading(false);
          gtag_report_conversion()
        });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleCloseModalImage = () => {
    setShowModalImage(false);
    // Open modal after 15 seconds
    if (timePage === 0) {
      setTimeout(() => {
        setTimePage(15)
        setShowModalImage(true)
      }, "15000");
    }
  }

  const handleShowModalGrants = () => {
    // function to show contact modal for grants
    setFocusCampaign('grant')
    setShowModal(true);
    setExpandedMenu(false);
  }

  const changeLanguage = code => {
    i18n.changeLanguage(code);
    setExpandedMenu(false);
    updateModalImage()
  }

  //const closeBottomBar = () => {
  //  setBottomBarDisplayed(false);
  //}

  const toggleNavbar = () => {
    expandedMenu ? setExpandedMenu(false) : setExpandedMenu(true);
  }

  const selectVideo = videoId => {
    setVideo(videoId);
    setExpandedMenu(false);
  }

  const selectVideoModal = (videoId, modal) => {
    if (modal) {
      setVideoModal(videoId);
      setIsOpenVideoModal(true);

      // Show modal contact after 50 seconds
      //const timer = setTimeout(() => {
      //  setFocusCampaign('grant')
      //  handleShowModalAuto();
      //}, 50000);
      //return () => clearTimeout(timer);
    }

    setVideo(videoId);
    setExpandedMenu(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [app]);

  useEffect(() => {
    // Show PopUp with text about grants or jobFair
    // sourceUrl ex. Twitter, googleAds
    const params = new Map(window.location.search.slice(1).split('&').map(kv => kv.split('=')))
    let lang = params.get('lang')
    let campaign = params.get('campaign')
    let userEmail = params.get('email')
    let userName = params.get('name')
    let userPhone = params.get('phone')
    let sourceUrl = params.get('source')
    let page = params.get('page')
    let userEmailSession

    if (page === 'workshop') {
      setPage('workshop')
    }

    if (lang) {
      if (lang === 'pt') {
        i18n.changeLanguage('po');
      } else {
        i18n.changeLanguage(lang);
      } 
    }

    if (campaign){
      setFocusCampaign(campaign)
    } else {
      // 50% of users in web will see jobfair
      if (Math.random() < 0.5) {
        setFocusCampaign('grant')
      } else {
        setFocusCampaign('grant')
      }
    }

    if (sourceUrl){
      setSource(sourceUrl)
    }

    if (userEmail) {
      sessionStorage.setItem('userEmail', userEmail)
    }

    if (userName) {
      sessionStorage.setItem('userName', userName)  
    }
    
    if (userPhone) {
      sessionStorage.setItem('userPhone', userPhone)
    }
    
    userEmailSession = sessionStorage.getItem('userEmail')  
    if (!userEmailSession) {
      // Open modal when open page (automatically)
      setFocusCampaign('grant')
      setGrantText('text2')
      setShowModalImage(true);
    }

  }, [])

  return (
    <div className="App">
      <CustomModal 
        focusCampaign={focusCampaign} 
        grantText={grantText} 
        showModal={showModal} 
        handleCloseModal={handleCloseModal} 
        changeLanguage={changeLanguage}
        submitHandler={submitHandler}
        messageSent={messageSent}
        isLoading={isLoading}
        withImage={true}
        source={source}
      />
      <ModalImage 
        showModalImage={showModalImage} 
        handleCloseModalImage={handleCloseModalImage} 
        changeLanguage={changeLanguage}
        modalImage={modalImage}
      />
      <MainNavigation 
        app={app}
        toggleNavbar={toggleNavbar} 
        expandedMenu={expandedMenu} 
        selectVideo={selectVideo} 
        changeLanguage={changeLanguage}
        selectVideoModal={selectVideoModal}
        handleShowModalGrants={handleShowModalGrants}
      />
      {/*
        bottomBarDisplayed &&
        <>
        <div className={`bottom-bar first-mobile ${show.bottomBar && "bottom-bar__transformed"}`} ref={ourRef}>
          <div className="bottom-bar__container">
            <div className="bottom-bar__buttons">
              <Modal button={t('modal_13')} styl="bottom-bar__btn bottom-bar__button-2" />
              <div className="bottom-bar-words">
                <h1>{t('modal_11')}</h1>
                <h2>{t('modal_12')}</h2>
              </div>
            </div>
            <div className="bottom-bar__close" onClick={closeBottomBar}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        <div className={`bottom-bar second-mobile ${show.bottomBar && "bottom-bar__transformed"}`} ref={ourRef}>
          <div className="bottom-bar__container">
            <div className="bottom-bar__buttons first-desktop">
              <div className="bottom-bar-words">
                <h1>{t('modal_11')}</h1>
                <h2>{t('modal_12')}</h2>
              </div>
              <Modal button={t('modal_13')} styl="bottom-bar__btn bottom-bar__button-2" />
            </div>

            <div className="bottom-bar__buttons">
                <div className="bottom-bar-words">
                  <h1>{t('modal_jobfair_4')}<span>{t('modal_jobfair_5')}</span></h1>
                </div>
              <a
                activeClass="active"
                href="https://buy.stripe.com/bIYcQkdhB55y9zy4gh"
                target="_blank" 
                rel="noopener noreferrer" 
                className="learn-more"
              >
                <div className="bottom-bar__btn bottom-bar__button-2">
                    {t('modal_jobfair_10')}
                </div>
              </a>
            </div>
            <div className="bottom-bar__close" onClick={closeBottomBar}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </div>
        </>
      */}
      {(app === '' && page === '') && (
        <React.Fragment>
          <Cover toggleNavbar={toggleNavbar} expandedMenu={expandedMenu} video={video} selectVideo={selectVideo} selectVideoModal={selectVideoModal} />
          <Demand />
          <Certificates />
          <Instructores selectVideoModal={selectVideoModal} />
          <About />
          <Partners />
          <Contact focusCampaign={focusCampaign} source={source}/>
        </React.Fragment>
      )}

      {(app === 'workshop' || page === 'workshop') && (
        <Workshop toggleNavbar={toggleNavbar} expandedMenu={expandedMenu} selectVideo={selectVideo} selectVideoModal={selectVideoModal} />
      )}

      {/* {(app === 'media') && (
        <Media toggleNavbar={toggleNavbar} expandedMenu={expandedMenu} />
      )} */}
      {(app === 'calendar') && (
        <Calendar toggleNavbar={toggleNavbar} expandedMenu={expandedMenu} showModal={setShowModal} focusCampaign={setFocusCampaign} />
      )}

      {(app === 'privacy-policy') && (
        <PrivacyPolicy />
      )}

      {(app !== '' && app !== 'workshop' && app !== 'privacy-policy' && app !== 'calendar' ) &&  (
        <NotFound />
      )}

      <Footer />
      {<ModalVideo channel='vimeo' autoplay="true" allowFullScreen="true" isOpen={isOpenVideoModal} videoId={t("vvideo" + videoModal)} onClose={() => setIsOpenVideoModal(false)} />}
    </div >
  );
}

export default App;
