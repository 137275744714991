import React from "react";
import { useTranslation } from "react-i18next";
//import { Link as LinkRouter } from 'react-router-dom'
import "./Media.css";
import "./Calendar.css";
import Evento from "./Evento";

import img_cal1 from '../Assets/img/img_cal1.jpg';
import img_cal5 from '../Assets/img/img_cal5.jpg';
import img_cal8 from '../Assets/img/img_cal8.jpg';
import img_cal9 from '../Assets/img/img_cal9.jpg';

import img_cal15 from '../Assets/img/img_cal15.jpg';
import img_cal14 from '../Assets/img/img_cal14.jpg';


const Calendar = (props) => {
      const { toggleNavbar, expandedMenu} = props;
      const { t } = useTranslation();

      const closeNavbar = () => {
            expandedMenu && toggleNavbar(false);
      };

      return (
            <div
                  className="media-section"
                  id="media-pointer"
                  onClick={() => {
                        closeNavbar();
                  }}
            >
                  <div className="media">
                        <div className="wrapper">
                              <div className="media-list">
                                    <div className="media-header">
                                          <h3>{t("calendar_title3")}</h3>
                                          <h4>{t("calendar_title2")}</h4>
                                    </div>
                              </div>
                              <div className="media-main">
                                    <div className="media-list2">
                                          <Evento
                                                mounth={t("date11")}
                                                day_init="19"
                                                mounth2={t("date11")}
                                                day_final=""
                                                title={t("event1")}
                                                img={img_cal1}
                                                button={t("button1")}
                                                lower={t("online")}
                                                link={'https://www.eventbrite.com/e/1024306978747?aff=website'}
                                          />
                                          <Evento
                                                mounth={t("date11")}
                                                day_init="19"
                                                mounth2={t("date11")}
                                                day_final=""
                                                title={t("event2")}
                                                img={img_cal15}
                                                button={t("button1")}
                                                lower={t("online")}
                                                link={'https://www.eventbrite.com/e/1026789684587?aff=website'}
                                          />
                                          <Evento
                                                mounth={t("date11")}
                                                day_init="19"
                                                mounth2={t("date11")}
                                                day_final=""
                                                title={t("event3")}
                                                img={img_cal8}
                                                button={t("button1")}
                                                lower={t("online")}
                                                link={'https://www.eventbrite.com/e/1024344330467?aff=website'}
                                          />
                                          <Evento
                                                mounth={t("date11")}
                                                day_init="19"
                                                mounth2={t("date11")}
                                                day_final=""
                                                title={t("event4")}
                                                img={img_cal14}
                                                button={t("button1")}
                                                lower={t("online")}
                                                link={'https://www.eventbrite.com/e/1026789905247?aff=website'}
                                          />

                                    </div>
                                    <div className="media-list2">
                                          <Evento
                                                mounth={t("date5")}
                                                day_init="6"
                                                mounth2={t("date5")}
                                                day_final=""
                                                title={t("event5")}
                                                img={img_cal5}
                                                button={t("button1")}
                                                lower={t("jacksonville")}
                                          />
                                          <Evento
                                                mounth={t("date6")}
                                                day_init="5"
                                                mounth2={t("date6")}
                                                day_final=""
                                                title={t("event6")}
                                                img={img_cal9}
                                                button={t("button1")}
                                                lower={"San Juan"}
                                          />
                                    </div>
                              </div>
                        </div>
                  </div>
                  {/* <div className="media-footer">
                        <div className="wrapper">
                              <div className="media-footer-text">
                                    <h4>{t('media_footer_title')}</h4>
                                    <p>{t('media_footer_subtitle')}</p>
                              </div>
                              <div className="media-footer-button">
                                    <LinkRouter
                                          activeClass="active"
                                          to="/workshop"
                                          spy={true}
                                          smooth={true}
                                          duration={700}
                                          className="btn btn-outline-green"
                                    >
                                          <span>{`${t('media_footer_button')}`}</span>
                                    </LinkRouter>
                              </div>
                        </div>
                  </div>*/}
            </div>
      );
};

export default Calendar;