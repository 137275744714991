import React from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../Assets/img/GreenBookAcademy.Logo.png';
import fot from '../Assets/img/fotoabout.png';

import './About.css';

const About = () => {
    const { t } = useTranslation();
    return (
        <div className="about-section" id="about-pointer">
            <div className="about-header">
                <h1>{t('about-0')}</h1>
                <h2>{t('about-1')}<span>{t('about-2')}<span className="green">{t('about-3')}</span>{t('about-4')}</span></h2>
            </div>
            <div className="about-main" id="about-pointer">
                <div className="about-main-izq">
                    <div className="about-main-izq-photo" style={{ backgroundImage: `url(${fot})` }} alt="About Greenbook Academy"></div>
                </div>
                <div className="about-main-der">
                    <div className="about-main-inner-der">
                        <img src={logo} alt="logo" />
                        <p>{t('about-5')}</p>
                        <p>{t('about-8')}</p>
                        <a href="#contactSection" ><button type="submit" className="about-boton">{`${t('about-6')}`}</button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;