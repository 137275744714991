import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

import './Certificates.css';
import logo1 from '../Assets/img/clock.png';
import logo2 from '../Assets/img/computer.png';

const Certificates = () => {
    const { t } = useTranslation();
    return (
        <div className="certificates-section" id="certificates-pointer">
            <div className="certificates-header">
                <h2>{t('certificates-1')}</h2>
                <h3>{t('certificates-2')}</h3>
            </div>
            <div className="certificates-main">
                <div className="wrapper">
                    <div className="certificates-card orange">
                        <h4>{t('certificates-card-1')}</h4>
                        <p>{t('certificates-card-2')}</p>
                        <div className="certificates-icons">
                            <div>
                                <img src={logo1} alt="clock icon" />
                                <p><strong>{t('certificates-card-3')}</strong> {t('certificates-card-4')}</p>
                            </div>
                            <div>
                                <img src={logo2} alt="computer icon" />
                                <p>{t('certificates-card-5')}</p>
                            </div>
                        </div>
                        <Link
                            activeClass="active"
                            to="contactSection"
                            spy={true}
                            smooth={true}
                            duration={700}
                            className="btn btn-lg"
                        >
                            <span>{`${t('certificates-card-6')}`}</span>
                        </Link>
                    </div>
                    <div className="certificates-card green">
                        <h4>{t('certificates-card2-1')}</h4>
                        <p>{t('certificates-card2-2')}</p>
                        <div className="certificates-icons">
                            <div>
                                <img src={logo1} alt="clock icon" />
                                <p><strong>{t('certificates-card2-3')}</strong> {t('certificates-card2-4')}</p>
                            </div>
                            <div>
                                <img src={logo2} alt="computer icon" />
                                <p>{t('certificates-card2-5')}</p>
                            </div>
                        </div>
                        <Link
                            activeClass="active"
                            to="contactSection"
                            spy={true}
                            smooth={true}
                            duration={700}
                            className="btn btn-lg"
                        >
                            <span>{`${t('certificates-card2-6')}`}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Certificates;