import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import Vimeo from "@u-wave/react-vimeo";
import 'react-multi-carousel/lib/styles.css';

import logo0 from '../Assets/img/kgif2.gif';
import logo1 from '../Assets/img/logoHorticulture.png';
import logo2 from '../Assets/img/LogoManufacture.png';
import logo3 from '../Assets/img/LogoCompliance.png';
import logo4 from '../Assets/img/logoPatient.png';
import logo5 from '../Assets/img/logoMed.png';
import logo6 from '../Assets/img/LogoHemp.png';

import videoThumb1 from '../Assets/img/video1.jpg';
import videoThumb2 from '../Assets/img/video2.jpg';
import videoThumb3 from '../Assets/img/video3.jpg';
import videoThumb4 from '../Assets/img/video4.jpg';
import videoThumb5 from '../Assets/img/video5.jpg';
import videoThumb6 from '../Assets/img/video6.jpg';
import videoThumb7 from '../Assets/img/video7.jpg';
import videoThumb8 from '../Assets/img/video8.jpg';

import './Cover.css';


const coursesList = [
    {
        id: '0',
        logo: logo1,
        thumb: videoThumb1,
    },
    {
        id: '1',
        logo: logo2,
        thumb: videoThumb2,
    },
    {
        id: '2',
        logo: logo3,
        thumb: videoThumb3,
    },
    {
        id: '3',
        logo: logo2,
        thumb: videoThumb4,
    },
    {
        id: '4',
        logo: logo4,
        thumb: videoThumb5,
    },
    {
        id: '5',
        logo: logo4,
        thumb: videoThumb6,
    },
    {
        id: '6',
        logo: logo6,
        thumb: videoThumb7,
    },
    {
        id: '7',
        logo: logo5,
        thumb: videoThumb8,
    },
];

const responsive = {
    largeDesktop: {
        breakpoint: { max: 3000, min: 1320 },
        items: 5,
        slidesToSlide: 1 // optional, default to 1.
    },
    desktop: {
        breakpoint: { max: 1320, min: 1120 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1120, min: 464 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 500, min: 0 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    }
};


const Cover = props => {
    const { toggleNavbar, expandedMenu, video, selectVideoModal } = props
    const { t } = useTranslation();

    const closeNavbar = () => {
        expandedMenu && toggleNavbar(false);
    }

    const onEndVideo = () => {
        // Reproduce next video 
        if (coursesList.length === video) {
            selectVideoModal(1, false)
        } else {
            selectVideoModal(parseInt(video) + 1, false)
        }
    }

    return (
        <div className="cover-section" id="careers-pointer" onClick={() => { closeNavbar() }}>
            <div className="cover-arriba">
                <div className="wrapper">
                    <div className="cover-arriba-first">
                        <img src={logo0} alt="Greenbook Academy K" />
                        <h1>{t('cover_1')}<span>{t('cover_2')}</span></h1>
                        <h2>{t('cover_3')}</h2>
                        <button className="btn btn-outline-green" onClick={toggleNavbar}>{`${t('cover_button')}`}</button>
                    </div>
                    <div className="cover-arriba-second">
                        <div className="embed-responsive embed-responsive-16by9">
                            <Vimeo video={t('video' + video)} muted={true} onEnd={() => onEndVideo()} autoplay />
                        </div>
                        <div className="cover-abajo-vid big">
                            <div className="cover-abajo-vid-der">
                                <div className="cover-abajo-vid-img">
                                    <img src={coursesList[video].logo} alt="videologo" />
                                </div>
                                <div className="cover-abajo-vid-word">
                                    <h6>{t('video_subtitle_' + video)}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cover-abajo" onClick={() => { closeNavbar() }}>
                <div className="cover-abajo-der">
                    { window.innerWidth && window.innerWidth > 1024
                        ? (
                            <Carousel responsive={responsive} infinite={true} >
                                {coursesList.map(item => (
                                    <div className="cover-abajo-vid" key={item.id} onClick={() => selectVideoModal(item.id, true)}>
                                        <div className="cover-abajo-vid-izq">
                                            <img src={item.thumb} alt="videologo" />
                                        </div>
                                        <div className="cover-abajo-vid-der">
                                            <div className="cover-abajo-vid-img">
                                                <img src={item.logo} alt="videologo" />
                                            </div>
                                            <div className="cover-abajo-vid-word">
                                                <h6>{t('video_subtitle_' + item.id)}</h6>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        ) : (
                            coursesList.map(item => (
                                <div className="cover-abajo-vid" key={item.id} onClick={() => selectVideoModal(item.id, true)}>
                                    <div className="cover-abajo-vid-izq">
                                        <img src={item.thumb} alt="videologo" />
                                    </div>
                                    <div className="cover-abajo-vid-der">
                                        <div className="cover-abajo-vid-img">
                                            <img src={item.logo} alt="videologo" />
                                        </div>
                                        <div className="cover-abajo-vid-word">
                                            <h6>{t('video_subtitle_' + item.id)}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Cover;