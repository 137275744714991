import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import Brand from '../Assets/img/logo-greenbook.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faInstagram, 
    faFacebook, 
    faYoutube, 
    faLinkedin,
    faTwitter} from '@fortawesome/free-brands-svg-icons'
//import {faPhone} from '@fortawesome/free-solid-svg-icons'

import './Footer.css';

const Footer = () => {

    const { t } = useTranslation();

    return (
        <div className="foot">
            <div className="footer">
                <div className="foot-main">
                    <div className="footsquare1">
                        <Link
                            activeClass="active"
                            to="coverSection"
                            spy={true}
                            smooth={true}
                            duration={700}
                            className="footer-link"
                            offset={-50}
                        >
                            <img src={Brand} alt="Greenbook Academy" className="brand" />
                            <h4>{t('foot-3')}<span>{t('foot-4')}</span> {t('foot-5')}</h4>
                        </Link>
                    </div>
                    <div className="footsquare2">
                        <ul>
                            <Link
                                activeClass="active"
                                to="careers-pointer"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="footer-link"
                                offset={-50}
                            >
                                {t('header-1')}
                            </Link>
                            <Link
                                activeClass="active"
                                to="instructors-pointer"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="footer-link"
                                offset={-50}
                            >
                                {t('header-2')}
                            </Link>
                            <Link
                                activeClass="active"
                                to="about-pointer"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="footer-link"
                                offset={-50}
                            >
                                {t('header-3')}
                            </Link>
                            <Link
                                activeClass="active"
                                to="contactSection"
                                spy={true}
                                smooth={true}
                                duration={700}
                                className="footer-link"
                                offset={-50}
                            >
                                {t('header-4')}
                            </Link>
                        </ul>
                    </div>
                    <div className="footsquare3">
                        <ul>
                            <h3>info@greenbookacademy.com</h3>
                            {/* <h3><FontAwesomeIcon icon={faPhone} /></h3>*/}
                            <div className="social-icons__container">
                                <a href="https://www.linkedin.com/company/greenbookacademy" target="__blank">
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                                <a href="https://twitter.com/GreenbookA" target="__blank">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                                <a href="https://instagram.com/greenbook.academy" target="__blank">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href="https://www.facebook.com/GreenBookAcademyUs/" target="__blank">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                                {/* 
                                <a href="https://wa.me/14072275803" target="__blank">
                                    <FontAwesomeIcon icon={faWhatsapp} />
                                </a>
                                */}
                                <a href="https://m.youtube.com/channel/UCxsBxpimmfHHwg9WGxZ7bcA" target="__blank">
                                    <FontAwesomeIcon icon={faYoutube} />
                                </a>
                                {/* <a href="https://www.tiktok.com/@greenbookacademy" target="__blank">
                                    <FontAwesomeIcon icon={faTiktok} />
                                </a> */}
                            </div>


                            {/*<li>
                            <div className="footer-country-flags__container">
                                <div className="eng" onClick={() => changeLanguage('en')}><span><img src={EnglishFlag} alt="English" /></span></div>
                                <div className="eng" onClick={() => changeLanguage('es')}><span><img src={SpainFlag} alt="Español" /></span></div>
                                <div className="eng" onClick={() => changeLanguage('po')}><span><img src={PortugalFlag} alt="Português" /></span></div>
                            </div>
                        </li>*/}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="bottom-footer__container">
                    <p>The Green Book Academy &copy;</p>
                    <p>{t('foot-1')}<span><a href={`mailto:${t('foot-2')}`}>{t('foot-2')}</a></span></p>
                </div>
            </div>
        </div>
    )
}



export default Footer;