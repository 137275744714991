
import React, { useEffect, useState } from 'react';
import CustomModal from './CustomModal';
import i18n from '../i18n';
import { Link as LinkPage } from 'react-scroll';
import { Link as LinkRouter, useParams } from 'react-router-dom'
import '../App.css';

function Modal(props) {
    const grantText = 'text1'
    const app = useParams().app || ''
    const  submitHandler=true;
    // eslint-disable-next-line
    const [expandedMenu, setExpandedMenu] = useState(false);
   // eslint-disable-next-line
    const [messageSent, setMessageSent] = useState(false);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [focusCampaign, setFocusCampaign] = useState('grant');
    // eslint-disable-next-line
    const [source, setSource] = useState('');
    
    const Link = app === "" ? LinkPage : LinkRouter
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);

    const handleShowModalGrants = () => {
        setFocusCampaign('grant')
        setShowModal(true);
        setExpandedMenu(false);
    }
    const changeLanguage = code => {
        i18n.changeLanguage(code);
        setExpandedMenu(false);
    }
    useEffect(() => {
	    window.scrollTo(0, 0);
	}, [app]);

    return (
        <>
            <CustomModal
                focusCampaign={focusCampaign}
                grantText={grantText}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                changeLanguage={changeLanguage}
                submitHandler={submitHandler}
                messageSent={messageSent}
                isLoading={isLoading}
                withImage={true}
                source={source}
            />
            <Link
                onClick={() => handleShowModalGrants()}
                activeClass="active"
                to="#"
                spy={true}
                smooth={true}
                duration={700}
                offset={-61}
                className="learn-more"
            >
                <div className={props.styl}>
                {props.button}
                </div> 
            </Link>
        </>
    );
}
export default Modal;