import React from 'react';
import { useTranslation } from 'react-i18next';

import compu from '../Assets/img/compu.png';
import './Demand.css';

const Demand = () => {
    const { t } = useTranslation();
    return (
        <div className="demand-section">
            <div className="demand-arriba">
                <div className="demand-arriba-izq">
                    <img src={compu} alt="responsive" />
                </div>
                <div className="demand-arriba-der">
                    <div>
                        <h2>{t('demand_1')} <span>{t('demand_2')}</span></h2>
                        <h3>{t('demand_3')}</h3>
                    </div>
                </div>
            </div>
            <div className="demand-abajo">
                <div className="demand-abajo-o">
                    <h5>{t('demand_4')}</h5>
                    <h6>{t('demand_5')}</h6>
                </div>
                <div className="demand-abajo-o">
                    <h5>{t('demand_6')}</h5>
                    <h6>{t('demand_7')}</h6>
                </div>
                <div className="demand-abajo-o">
                    <h5>{t('demand_8')}</h5>
                    <h6>{t('demand_9')}</h6>
                </div>
            </div>
        </div>
    )
}

export default Demand;