import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal'
import i18n from './../i18n';
import ReactLoading from 'react-loading';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import popupfoto from './../Assets/img/greenbook_popup.jpg';
import popupBnobleEn from './../Assets/img/FL_BNoble_en.png';
import popupBnobleEs from './../Assets/img/FL_BNoble_es.png';
import popupBnoblePt from './../Assets/img/FL_BNoble_pt.png';


import './../App.css';
import './CustomModal.css';

const CustomModal = props => {
  const [user, setUser] = useState({ user_name: "", user_email: "", user_phone: "" });
  const { t } = useTranslation();
  const {focusCampaign, grantText, showModal, handleCloseModal, changeLanguage, submitHandler, messageSent, isLoading, withImage, source} = props

  useEffect(() => {
    let userEmailSession = sessionStorage.getItem('userEmail')
    let userNameSession = sessionStorage.getItem('userName')
    let userPhoneSession = sessionStorage.getItem('userPhone')

    let name = ''
    let email = ''
    let phone = ''

    if (userNameSession) {
      name = userNameSession
    }

    if (userEmailSession) {
      email = userEmailSession
    }

    if (userPhoneSession) {
      phone = userPhoneSession
    }

    setUser({ 'user_name': name, 'user_email': email, 'user_phone': phone });

  }, [showModal])

  const handlePhoneChange = (value) => {
    setUser({ ...user, 'user_phone': value });
  };

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  

  return (
    <Modal show={showModal} onHide={handleCloseModal} className={`modal-${focusCampaign} modal-${grantText} ${withImage ? "half" : "full"}`} >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body >
        <div className="modalx-main">
          {withImage &&
            <div className="modalx-izq">
              {focusCampaign === 'jobfair' ?
                <div className="modalx-photo" style={{ backgroundImage: `url(${popupfoto})` }} alt="Greenbook"></div>
              :
                (grantText === 'text1' ?
                  <div className="modalx-photo" style={{ backgroundImage: `url(${popupfoto})` }} alt="Greenbook"></div>
                :
                  (
                    <a href={t('form')} target="_blank" rel="noopener noreferrer" >
                      <div className="modalx-photo" style={{ backgroundImage: `url(${i18n.language === 'en' ? popupBnobleEn : (i18n.language === 'es' ? popupBnobleEs : popupBnoblePt )})` }} alt="BNoble grant"></div>
                    </a>
                  )
                )
              }
            </div>   
          }
          <div className="modalx-der">
            <div className="language-options-modalx">
              <div className={`language_modalx ${i18n.language === 'en' ? 'selected' : ''}`} onClick={() => { changeLanguage('en') }}>
                <span>
                  EN
                </span>
              </div>
              <div className={`language_modalx ${i18n.language === 'es' ? 'selected' : ''}`} onClick={() => { changeLanguage('es') }}>
                <span>
                  ES
                </span>
              </div>
              <div className={`language_modalx ${i18n.language === 'po' ? 'selected' : ''}`} onClick={() => { changeLanguage('po') }}>
                <span>
                  PT
                </span>
              </div>
            </div>
            {focusCampaign === 'jobfair' ?
              <div className="texto_modal">
                <h1>{t('modal_jobfair_6')} <span>{t('modal_jobfair_7')}</span>{t('modal_jobfair_8')}</h1>
              </div>
            :
              <div className="texto_modal">
              {grantText === 'text1' ?
                <h1>{t('modal_4')}</h1>
              :
                <h1>{t('modal_1')} <span>{t('modal_2')}</span>{t('modal_3')}</h1>
              }
              </div>
            }
            <div className="modalx_formulario" >
              <div className="">
                {!messageSent &&
                  <React.Fragment>
                    <div className="">
                      <form onSubmit={submitHandler} className="">
                        <input type="hidden" name="email_number" value={Math.random() * 100000 | 0} />
                        <input type="hidden" name="focus_campaign" value={focusCampaign} />
                        <input type="hidden" name="source" value={source} />
                        <input type="hidden" name="language" value={i18n.language === 'en' ? 'English' : i18n.language === 'es' ? 'Español' : 'Portugues'} />
                        <div>
                          <div>
                            {focusCampaign === 'jobfair' ?
                              <div className="modalx_renglones checkbox-group text-left">
                                <label htmlFor="workshop"><strong>{t('modal-jobfair-workshop-0')}</strong></label>
                                <div>
                                  <label><input type="checkbox" name="workshop" value="Próximamente: marzo" /> {`${t('modal-jobfair-workshop-3')}`}</label>
                                </div>
                              </div>
                              : ''
                            }
                          </div>
                          <div className="modalx_renglones">
                            <input type="text" name="user_name" className="modalx_innerrenglon" placeholder={`${t('contact-3')}`} value={user.user_name} onChange={handleChange} required />
                          </div>
                          <div className="modalx_renglones">
                            <input type="email" className="modalx_innerrenglon" id="emailAddress" name="user_email" placeholder={`${t('contact-4')}`} value={user.user_email} onChange={handleChange} required />
                          </div>
                          <div className="">
                            <div className="modalx_renglones">
                              <PhoneInput
                                international
                                name="user_phone"
                                placeholder={`${t('contact-5')}`}
                                value={user.user_phone}
                                onChange={handlePhoneChange}
                                required
                                />
                            </div>
                            <div className="modalx_renglones">
                              <select className="modalx_innerrenglon" name="user_career">
                                {focusCampaign === 'jobfair' ?
                                  <option value="" disabled selected hidden>{`${t('contact-jobfair-6')}`}</option>
                                :
                                  <option value="" disabled selected hidden>{`${t('contact-6')}`}</option>
                                }
                                <option value={`${t('career-option-1')}`}>{`${t('career-option-1')}`}</option>
                                <option value={`${t('career-option-2')}`}>{`${t('career-option-2')}`}</option>
                                <option value={`${t('career-option-3')}`}>{`${t('career-option-3')}`}</option>
                                <option value={`${t('career-option-4')}`}>{`${t('career-option-4')}`}</option>
                                <option value={`${t('career-option-5')}`}>{`${t('career-option-5')}`}</option>
                                <option value={`${t('career-option-6')}`}>{`${t('career-option-6')}`}</option>
                                <option value={`${t('career-option-7')}`}>{`${t('career-option-7')}`}</option>
                                <option value={`${t('career-option-8')}`}>{`${t('career-option-8')}`}</option>
                                <option value={`${t('career-option-9')}`}>{`${t('career-option-9')}`}</option>
                                <option value={`${t('career-option-10')}`}>{`${t('career-option-10')}`}</option>
                                <option value={`${t('career-option-11')}`}>{`${t('career-option-11')}`}</option>
                                <option value={`${t('career-option-12')}`}>{`${t('career-option-12')}`}</option>
                              </select>
                            </div>
                            <div className="modalx-option">
                              <input className="form-check-input" type="radio" name="inquiryType" id="Facultyx" value="Faculty" />
                              <label htmlFor="Faculty">{t('modal_8')}</label>
                            </div>
                          </div>
                          <div className="modalx_btn_main">

                            {isLoading &&
                              <div className="">
                                <ReactLoading type="spinningBubbles" color="#268869" height={60} width={60} />
                              </div>
                            }
                            {!isLoading &&
                              focusCampaign === 'jobfair' ?
                                <button type="submit" className="modalx_btn_">{`${t('modal_jobfair_9')}`}</button>
                              :
                                (grantText === 'text1' ?
                                  <button type="submit" className="modalx_btn_">{`${t('modal_14')}`}</button>
                                : 
                                  <button type="submit" className="modalx_btn_">{`${t('modal_9')}`}</button>
                                )
                            }
                          </div>
                        </div>
                      </form>
                    </div>
                  </React.Fragment>
                }
                {(messageSent && !isLoading) &&
                  <div className="success-message-sent">
                    <h3>{t('sent-2')}</h3>
                    <h5>{t('sent-3')}</h5>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CustomModal;
