import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//import { Link } from 'react-scroll';

import profe1 from '../Assets/img/Dilon_White.jpeg';
import profe2 from '../Assets/img/riley_kirk.jpeg';
import profe3 from '../Assets/img/john_davis.png';
// import profe5 from '../Assets/img/marisha_morawska.png';
import profe6 from '../Assets/img/nick_garcia.jpg';
import profe7 from '../Assets/img/noemi_perez.png';
// import profe8 from '../Assets/img/al_rivera.png';
import profe9 from '../Assets/img/mieko_perez.jpg';

import './Instructores.css';
import './Coverx.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 1 // optional, default to 1.
    },
    bigtablet: {
        breakpoint: { max: 1024, min: 770 },
        items: 4,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 770, min: 500 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 500, min: 0 },
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const Instructores = props => {
    const { t } = useTranslation();
    const { selectVideoModal } = props
    return (
        <div className="instructores-section" id="instructors-pointer">
            <div className="instructores-header">
                <h3>{t('instructor_title_1')}</h3>
                <h4>{t('instructor_title_2')}</h4>
            </div>
            <div className="instructores-main">
                <Carousel responsive={responsive} infinite={true} >
                    <div className="instructores-main-slides" onClick={() => selectVideoModal('0', true)}>
                        <div className="instructores-main-slides-o">
                            <img src={profe1} alt="profe" />
                        </div>
                        <div className="instructores-text">
                            <h1>{t('profe_3')}</h1>
                            <h2>{t('profe_4')}</h2>
                        </div>
                    </div>
                    <div className="instructores-main-slides" onClick={() => selectVideoModal('1', true)}>
                        <div className="instructores-main-slides-o">
                            <img src={profe2} alt="profe" />
                        </div>
                        <div className="instructores-text">
                            <h1>{t('profe_5')}</h1>
                            <h2>{t('profe_6')}</h2>
                        </div>
                    </div>
                    <div className="instructores-main-slides" onClick={() => selectVideoModal('2', true)}>
                        <div className="instructores-main-slides-o">
                            <img src={profe3} alt="profe" />
                        </div>
                        <div className="instructores-text">
                            <h1>{t('profe_7')}</h1>
                            <h2>{t('profe_8')}</h2>
                        </div>
                    </div>
                    <div className="instructores-main-slides" onClick={() => selectVideoModal('3', true)}>
                        <div className="instructores-main-slides-o">
                            <img src={profe6} alt="profe" />
                        </div>
                        <div className="instructores-text">
                            <h1>{t('profe_13')}</h1>
                            <h2>{t('profe_14')}</h2>
                        </div>
                    </div>
                    <div className="instructores-main-slides" onClick={() => selectVideoModal('4', true)}>
                        <div className="instructores-main-slides-o">
                            <div className="instructores-photo" style={{ backgroundImage: `url(${profe7})` }} alt="profe"></div>
                        </div>
                        <div className="instructores-text">
                            <h1>{t('profe_15')}</h1>
                            <h2>{t('profe_16')}</h2>
                        </div>
                    </div>
                    <div className="instructores-main-slides" onClick={() => selectVideoModal('5', true)}>
                        <div className="instructores-main-slides-o">
                            <div className="instructores-photo" style={{ backgroundImage: `url(${profe9})` }} alt="profe"></div>
                        </div>
                        <div className="instructores-text">
                            <h1>{t('profe_19')}</h1>
                            <h2>{t('profe_20')}</h2>
                        </div>
                    </div>
                    {/* <div className="instructores-main-slides" onClick={() => selectVideoModal('6', true)}>
                        <div className="instructores-main-slides-o">
                            <img src={profe5} alt="profe" />
                        </div>
                        <div className="instructores-text">
                            <h1>{t('profe_11')}</h1>
                            <h2>{t('profe_12')}</h2>
                        </div>
                    </div>
                    <div className="instructores-main-slides" onClick={() => selectVideoModal('7', true)}>
                        <div className="instructores-main-slides-o">
                            <img src={profe8} alt="profe" />
                        </div>
                        <div className="instructores-text">
                            <h1>{t('profe_17')}</h1>
                            <h2>{t('profe_18')}</h2>
                        </div>
                    </div> */}
                </Carousel>
            </div>
            {/*
            <div className="instructores-footer">
                <div className="wrapper">
                    <div className="instructores-footer-text">
                        <h4>{t('profe_21')}</h4>
                        <p>{t('profe_22')}</p>
                    </div>
                    <div className="instructores-footer-button">
                        <Link
                            activeClass="active"
                            to="contactSection"
                            spy={true}
                            smooth={true}
                            duration={700}
                            className="btn btn-outline-green"
                        >
                            <span>{`${t('profe_23')}`}</span>
                        </Link>
                    </div>
                </div>
            </div>
            */}
        </div>
    )
}

export default Instructores;