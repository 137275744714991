import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link as LinkPage} from 'react-scroll';
import { Link as LinkRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import Logo from '../Assets/img/logomenu.gif';
import Logo2 from '../Assets/img/GreenBookAcademy.Logo.png';
import ResponsiveMenu from './ResponsiveMenu';
import { useTranslation } from 'react-i18next';

import i18n from '../i18n';

import './MainNavigation.css';

/*
Scroll ids

careers-pointer
instructors-pointer
about-pointer
contactSection
*/

const MainNavigation = props => {
    const { app, toggleNavbar, expandedMenu, changeLanguage, selectVideo, selectVideoModal, handleShowModalGrants } = props
    
    const Link = app === "" ? LinkPage : LinkRouter
    const { t } = useTranslation();

   

    return (
        expandedMenu ?
            <ResponsiveMenu toggleNavbar={toggleNavbar} changeLanguage={changeLanguage} selectVideo={selectVideo} selectVideoModal={selectVideoModal} handleShowModalGrants={handleShowModalGrants} />
        :
        <Navbar collapseOnSelect expand="lg" bg={`${expandedMenu ? 'dark' : 'light'}`} variant={`${expandedMenu ? 'dark' : 'light'}`} fixed="top" expanded={expandedMenu}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleNavbar} ><FontAwesomeIcon icon={faBars} color="white" /></Navbar.Toggle>
            <Navbar.Brand>
                <Link
                    activeClass="active"
                    to={app === "" ? "coverSection" : "/"}
                    spy={true}
                    smooth={true}
                    duration={700}
                    className="navbar-brand-img"
                    onClick={() => toggleNavbar(false)}
                >
                    <img src={`${expandedMenu ? Logo : Logo2}`} alt="GreenBook Academy Logo" />
                </Link>
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Link
                        onClick={toggleNavbar}
                        activeClass="active"
                        to={app === "" ? "#" : "/"}
                        spy={true}
                        smooth={true}
                        duration={700}
                        className="nav-link"
                        offset={-50}
                    >
                        {t('header-1')}
                    </Link>
                    <Link
                        activeClass="active"
                        to={app === "" ? "instructors-pointer" : "/"}
                        spy={true}
                        smooth={true}
                        duration={700}
                        className="nav-link"
                        offset={-50}
                    >
                        {t('header-2')}
                    </Link>
                    <Link
                        activeClass="active"
                        to={app === "" ? "about-pointer" : "/"}
                        spy={true}
                        smooth={true}
                        duration={700}
                        className="nav-link"
                        offset={-50}
                    >
                        {t('header-3')}
                    </Link>
                    <LinkRouter
                        activeClass="active"
                        to={app === "/" ? "" : "/calendar"}
                        spy={true}
                        smooth={true}
                        duration={700}
                        className="nav-link"
                        offset={-50}
                    >
                        {t('header-8')}
                    </LinkRouter>
                    <Link
                        activeClass="active"
                        to={app === "" ? "contactSection" : "/"}
                        spy={true}
                        smooth={true}
                        duration={700}
                        className="nav-link"
                        offset={-50}
                    >
                        {t('header-4')}
                    </Link>
                    <div className="mobile-language-options">
                        {i18n.language !== 'en' &&
                        <div className="mobile-language__container" onClick={()=>{changeLanguage('en')}}>
                            <span>English</span>
                        </div>
                        }
                        {i18n.language !== 'es' &&
                        <div className="mobile-language__container" onClick={()=>{changeLanguage('es')}}>
                            <span>Español</span>
                        </div>
                        }
                        {i18n.language !== 'po' &&
                        <div className="mobile-language__container" onClick={()=>{changeLanguage('po')}}>
                            <span>Português</span>
                        </div>
                        }
                    </div>
                    <div className="language-options">
                        <div className={`language__container ${i18n.language === 'en' ? 'selected' : ''}`} onClick={()=>{changeLanguage('en')}}>
                            <span>
                                EN
                            </span>
                        </div>
                        <div className={`language__container ${i18n.language === 'es' ? 'selected' : ''}`} onClick={()=>{changeLanguage('es')}}>
                            <span>
                                ES
                            </span>
                        </div>
                        <div className={`language__container ${i18n.language === 'po' ? 'selected' : ''}`} onClick={()=>{changeLanguage('po')}}>
                            <span>
                                PT
                            </span>
                        </div>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default MainNavigation;
