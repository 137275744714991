import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Carousel from "react-multi-carousel";
import Vimeo from "@u-wave/react-vimeo";
import "react-multi-carousel/lib/styles.css";

import logoHorticulture from "../Assets/img/logoHorticulture.png";
import logoCompliance from "../Assets/img/LogoCompliance.png";
import logoHemp from "../Assets/img/LogoHemp.png";
import logoMed from "../Assets/img/logoMed.png";

import videoThumb1 from "../Assets/img/01_workshop_video.png";
import videoThumb2 from "../Assets/img/02_workshop_video.png";
import videoThumb3 from "../Assets/img/03_workshop_video.png";
import videoThumb4 from "../Assets/img/04_workshop_video.png";

import "./Workshop.css";

const coursesList = [
  {
    id: 8,
    logo: logoHemp,
    thumb: videoThumb1,
  },
  {
    id: 9,
    logo: logoHorticulture,
    thumb: videoThumb2,
  },
  {
    id: 10,
    logo: logoMed,
    thumb: videoThumb3,
  },
  {
    id: 11,
    logo: logoCompliance,
    thumb: videoThumb4,
  },
];

const responsive = {
  largeDesktop: {
    breakpoint: { max: 3000, min: 1320 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1320, min: 1120 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1120, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 3,
    slidesToSlide: 1,
  },
};

const Workshop = (props) => {
  const { toggleNavbar, expandedMenu, selectVideoModal } = props;
  const { t } = useTranslation();

  const [currentVideo, setCurrentVideo] = useState(8);

  const closeNavbar = () => {
    expandedMenu && toggleNavbar(false);
  };

  const onEndVideo = () => {
    // Reproduce next video
    if (currentVideo === coursesList[coursesList.length - 1].id) {
      setCurrentVideo(coursesList[0].id);
      return;
    } else {
      setCurrentVideo((prevVideo) => prevVideo + 1);
      return;
    }
  };

  return (
    <div
      className="workshop-section"
      id="workshop-pointer"
      onClick={() => {
        closeNavbar();
      }}
    >
      <div className="workshop-arriba">
        <div className="wrapper">
          <div className="workshop-arriba-first">
            <h1>
              {t("workshop_1")}
              <span>{t("workshop_2")}</span>
            </h1>
            <h2>{t("workshop_3")}</h2>
          </div>
          <div className="workshop-arriba-second">
            <div className="embed-responsive embed-responsive-16by9">
              <Vimeo
                muted={true}
                video={t("vvideo" + currentVideo)}
                onEnd={() => onEndVideo()}
                autoplay
              />
            </div>
            <div className="workshop-abajo-vid big">
              <div className="workshop-abajo-vid-der">
                <div className="workshop-abajo-vid-img">
                  <img src={coursesList.find(item => item.id === currentVideo).logo} alt="video-logo" />
                </div>
                <div className="workshop-abajo-vid-word">
                  <h6>{t("video_subtitle_" + currentVideo)}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="workshop-abajo"
        onClick={() => {
          closeNavbar();
        }}
      >
        <div className="workshop-abajo-der">
          {window.innerWidth && window.innerWidth > 1024 ? (
            <Carousel responsive={responsive} infinite={true}>
              {coursesList.map(
                (item) =>
                  item.id !== currentVideo && (
                    <div
                      className="workshop-abajo-vid"
                      onClick={() => selectVideoModal(item.id, true)}
                      key={item.id}
                    >
                      <div className="workshop-abajo-vid-izq">
                        <img src={item.thumb} alt="videologo" />
                      </div>
                      <div className="workshop-abajo-vid-der">
                        <div className="workshop-abajo-vid-img">
                          <img src={item.logo} alt="videologo" />
                        </div>
                        <div className="workshop-abajo-vid-word">
                          <h6>{t("video_subtitle_" + item.id)}</h6>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </Carousel>
          ) : (
            coursesList.map(
              (item) =>
                item.id !== currentVideo && (
                  <div
                    className="workshop-abajo-vid"
                    onClick={() => selectVideoModal(item.id, true)}
                    key={item.id}
                  >
                    <div className="workshop-abajo-vid-izq">
                      <img src={item.thumb} alt="videologo" />
                    </div>
                    <div className="workshop-abajo-vid-der">
                      <div className="workshop-abajo-vid-img">
                        <img src={item.logo} alt="videologo" />
                      </div>
                      <div className="workshop-abajo-vid-word">
                        <h6>{t("video_subtitle_" + item.id)}</h6>
                      </div>
                    </div>
                  </div>
                )
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Workshop;
