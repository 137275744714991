// Event snippet for Suscribirse (1) conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
export const gtag_report_conversion = (url) => {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  window.gtag("event", "conversion", {
    send_to: "AW-10846966648/y6t5CN2avMcDEPimnrQo",
    event_callback: callback,
  });
  return false;
};
