import "./Media.css";
import "./Calendar.css";
import React from 'react';
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
function Evento(props) {
	const { link, button, mounth, day_init, mounth2, day_final, img, title, lower  } = props;
	const { t } = useTranslation();
	return (
		<div id="DIV_25">
			<div id="DIV_27">
			{mounth && lower &&(
			<div id="bgplace">
			<div id="place">
				{lower}
			</div>
			</div>
			)}
				{mounth && day_final ? (
					
					<div id="DIV_28">
						<div id="DIV_29">
							<div id="DIV_30">
								{mounth}
							</div>
							<div id="DIV_31">
								{day_init}
							</div>
						</div>
						<div id="DIV_32">
							<div id="DIV_33">
							</div>
							<div id="DIV_34">
								<div id="DIV_35">
									{mounth2}
								</div>
								<div id="DIV_36">
									{day_final}
								</div>
							</div>
						</div>
					</div>
					
				) : mounth && (
					<div id="DIV_28">
					<div id="DIV_29">
						<div id="DIV_30">
							{mounth}
						</div>
						<div id="DIV_31">
							{day_init}
						</div>
					</div>
				</div>	
				)}
				{img ? (
					<div id="DIV_37">
						<img alt="Virtual Bauhaus" src={img} id="IMG_38" />
					</div>
				) : null}
				<div id="DIV_39">
					<div id="DIV_40">
						{title}
					</div>
					<div id="DIV_41">
					</div>
					<div id="DIV_43">
						<div id="DIV_44">
							{link ? (
								<a href={link} target="_blank" rel="noopener noreferrer" className="button">{button}</a>
							) : (
								<Modal button={button} />
							)}
						</div>
					</div>
					<div id="DIV_45">
						{t("bilingual_event")}
					</div>
				</div>
			</div>
		</div>
	);
}
export default Evento;